import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

class Contactus extends Component {

    render() {

        return (
            <div>
                <section className='bg-default'>
                    <div className='row'>
                        <img className='img-fluid rounded' src="../../../assets/img/slide/contacus/contactus.jpg"></img>
                    </div>
                </section>
                <div className='container' style={{marginTop: '-150px'}}>
                    <div className='row'>
                        <div className='col-lg-7 bg-abu2'>
                            <h3 className='mt-3 mb-3'>Memiliki Pertanyaan?</h3>
                            <form action="forms/contact.php" method="post" role="form" class="php-email-form m-5">
                                <div class="row">
                                    <div class="col-md-6 form-group">
                                        <input type="text" name="name" class="form-control" id="name" placeholder="Nama" required />
                                    </div>
                                    <div class="col-md-6 form-group mt-3 mt-md-0">
                                        <input type="pendidikan" class="form-control" name="pendidikan" id="pendidikan" placeholder="Pendidikan Terakhir" required />
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-6 form-group">
                                        <input type="text" name="no_hp" class="form-control" id="no_hp" placeholder="No. HP" required />
                                    </div>
                                    <div class="col-md-6 form-group mt-3 mt-md-0">
                                        <input type="email" class="form-control" name="email" id="email" placeholder="Email" required />
                                    </div>
                                </div>
                                <div class="form-group mt-3">
                                <input type="sekolah" class="form-control" name="sekolah" id="sekolah" placeholder="Sekolah" required />
                                </div>
                                <div class="form-group mt-3">
                                    <textarea class="form-control" name="message" rows="5" placeholder="Apa Yang Bisa Kami Bantu" required></textarea>
                                </div>
                                <div class="text-center mt-3">
                                    <button className='btn-default' type="submit">Send Message</button>
                                </div>
                            </form>
                        </div>
                        <div className='col-lg-5 bg-default text-start'>
                            <div className='container'>
                                <h3 className='mt-5 mb-5'><strong>Kontak Kami</strong></h3>
                                <h4><strong><i class="bi bi-telephone"></i> 0812 3843 1234 </strong></h4>
                                <h5 className='mb-5'><strong><i class="bi bi-envelope"></i> info@niecindonesia.com</strong></h5>
                                <h4><strong>Denpasar Head Office</strong></h4>
                                <h4><strong>Jalan Buluh Indah 56,</strong></h4>
                                <h4 className='mb-5'><strong>Denpasar - Bali</strong></h4>
                                <div class="mt-3">
                                    <a href="https://id-id.facebook.com/niec.indonesia/" target='_blank' class="facebook font-default"><i class="bx bxl-facebook"></i></a>
                                    <a href="https://www.instagram.com/niec_indonesia/" target='_blank' class="instagram font-default"><i class="bx bxl-instagram"></i></a>
                                    <a href="https://www.tiktok.com/@niec_indonesia?lang=id-ID" target='_blank' class="instagram font-default"><i class="bx bxl-tiktok"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="contact" class="contact">
                    <div class="container mb-5">
                        <Tabs defaultActiveKey="denpasar" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="denpasar" title="Denpasar">
                                <div className='container'>
                                    <h4>Working Hours</h4>
                                    <h4>Senin - Sabtu</h4>
                                    <h4>09:00 WITA - 18:00 WITA</h4>
                                    <div>
                                        <iframe className='iframe-maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3944.5492299174907!2d115.19452601411855!3d-8.63919829023127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd23f4e5c54e043%3A0xbf4bc0b6d7634e96!2sNaresy%20International%20Education%20Consultant%20(NIEC)%20Bali!5e0!3m2!1sen!2sid!4v1656039400954!5m2!1sen!2sid" frameborder="0" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="yogyakarta" title="Yogyakarta">
                                <div className='container'>
                                    <h4>Working Hours</h4>
                                    <h4>Senin - Jumat</h4>
                                    <h4>08:30 WITA - 17:30 WITA</h4>
                                    <div>
                                        <iframe className='iframe-maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3952.8718111253147!2d110.37015581410856!3d-7.803393079626147!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a5783771c1d29%3A0x67b87f6980e74aac!2sNaresy%20International%20Education%20Consultant%20Indonesia!5e0!3m2!1sen!2sid!4v1656039484403!5m2!1sen!2sid" frameborder="0" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="makassar" title="Makassar">
                                <div className='container'>
                                    <h4>Working Hours</h4>
                                    <h4>Senin - Jumat</h4>
                                    <h4>09:00 WITA - 18:00 WITA</h4>
                                    <div>
                                        <iframe className='iframe-maps' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15895.101936207362!2d119.4494375!3d-5.1398125!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4ceb5e30dcef6b8a!2sNaresy%20International%20Education%20Consultant!5e0!3m2!1sid!2sid!4v1665455467016!5m2!1sid!2sid" frameborder="0" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="medan" title="Medan">
                                <div className='container'>
                                    <h4>Working Hours</h4>
                                    <h4>Senin - Jumat</h4>
                                    <h4>08:30 WITA - 17:30 WITA</h4>
                                    <div>
                                        <iframe className='iframe-maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2830.952470935811!2d98.65858637305513!3d3.587684996386463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x303131aa731ab377%3A0x226e36ab59c407cb!2sCradle%20Coworking%20and%20Event%20Space!5e1!3m2!1sen!2sid!4v1740120986268!5m2!1sen!2sid" frameborder="0" allowfullscreen=""></iframe>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="jakara_selatan" title="Jakarta Selatan">
                                <div className='container'>
                                    <h4>Working Hours</h4>
                                    <h4>Senin - Jumat</h4>
                                    <h4>08:00 WIB - 17:00 WIB</h4>
                                    <div>
                                    <iframe className='iframe-maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1982.893642076691!2d106.79806153156555!3d-6.291664309862976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f112096dc105%3A0xad9c9d9933ffe0ca!2sNIEC%20Indonesia%20Jakarta%20Selatan!5e0!3m2!1sen!2sid!4v1718675159144!5m2!1sen!2sid" frameborder="0" allowfullscreen=""></iframe>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="surabaya" title="Surabaya">
                                <div className='container'>
                                    <h4>Working Hours</h4>
                                    <h4>Senin - Jumat</h4>
                                    <h4>09:00 WIB - 18:00 WIB</h4>
                                    <div>
                                        <iframe className='iframe-maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.6010995211764!2d112.6967373734873!3d-7.286144492721192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fd7cd6f5a487%3A0x3c020eac2f1092f1!2sVoza%20Tower%20Surabaya!5e0!3m2!1sen!2sid!4v1739517624348!5m2!1sen!2sid" frameborder="0" allowfullscreen=""></iframe>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </section>
            </div>
        )

    }

}

export default (Contactus);